import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { components } from 'templates/components';
import { SEO } from 'components';
import { Container, useAviTheme } from '@aviationexam/core';
import { PageNode } from 'types/page';

interface PageTemplateProps {
  children: React.ReactNode;
}

function PageTemplate({ children }: PageTemplateProps) {
  const theme = useAviTheme();
  return (
    <article>
      <Container
        size="lg"
        style={{ paddingTop: theme.spacing.xl, paddingBottom: theme.spacing.xl }}
      >
        {children}
      </Container>
    </article>
  );
}

interface DataType {
  mdxPage: PageNode;
}

function Page({ data: { mdxPage: page } }: PageProps<DataType>) {
  return (
    <>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description}
        lang={page.frontmatter.locale}
      >
        {page.frontmatter.alternateSlug && (
          <link
            rel="alternate"
            hrefLang={page.frontmatter.locale === 'en' ? 'de' : 'en'}
            href={page.frontmatter.alternateSlug}
          />
        )}
      </SEO>
      <PageTemplate>
        <MDXProvider components={components}>
          <MDXRenderer>{page.parent.body}</MDXRenderer>
        </MDXProvider>
      </PageTemplate>
    </>
  );
}

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    mdxPage(id: { eq: $id }) {
      id
      parent {
        ... on Mdx {
          body
        }
      }
      frontmatter {
        title
        description
        locale
        alternateSlug
      }
    }
  }
`;
